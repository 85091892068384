<template>
  <div id="pay">
      <div class="alipay"></div>
      <div class="loader-box" v-show="showLoading">
          <div class="loader"></div>
          <div class="loader-text"></div>
      </div>
  </div>
</template>
  
<script>
import axios from 'axios';
export default {
  name: 'Pay',
  data() {
    return {
      orderOptions: {},
      zfbcontent:'',
      baseUrl:'https://xhs-kdxgs-universal-api.kdxgs.com/api/pay/choose_pay_method',
      testData:'',
      showLoading:false
    }
  },
  created(){
    this.orderOptions = this.$route.query
    if(JSON.stringify(this.orderOptions) != "{}"){
      
      this.getPayOptions()
      this.showLoading = true
    }else {
      window.location = `xhsdiscover://`
    }
  },
  mounted() {
   
  },
  methods: {
    getPayOptions(){
      axios.post(
        this.baseUrl,
      {
        ...this.orderOptions
      },
      {
        headers: {

          "Api-Open-From": this.orderOptions.pay_type,
        }
      }
    ).then(res=>{
      this.testData = res
     if(this.orderOptions.pay_way==1){
      const {data} = res.data
      this.zfbcontent = res
      document.querySelector('.alipay').innerHTML = data
			document.forms[0].submit()
     }else {
      //微信
        window.location.href = res.data.data.h5_url+'&redirect_url=https://wap.datzww.com/#/pay?'
     }
    })
    }
  }
}
</script>
  
<style lang="css" scoped>
#pay {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.loader-box {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.loader {
  width: 50px;
  padding: 8px;
  aspect-ratio: 1;
  border-radius: 50%;
  background: #25b09b;
  --_m: 
    conic-gradient(#0000 10%,#000),
    linear-gradient(#000 0 0) content-box;
  -webkit-mask: var(--_m);
          mask: var(--_m);
  -webkit-mask-composite: source-out;
          mask-composite: subtract;
  animation: l3 1s infinite linear;
}
@keyframes l3 {to{transform: rotate(1turn)}}



.loader-text {
  font-weight: bold;
  font-family: sans-serif;
  font-size: 20px;
  animation: l1 1s linear infinite alternate;
  margin-top: 20px;
}
.loader-text:before {
  content:"正在拉起支付"
}
@keyframes l1 {to{opacity: 0}}
</style>

  